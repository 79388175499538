import { useEffect } from "react";
import { navigate } from "gatsby";

export default function Nutritionist() {
	useEffect(() => {
		navigate("/booking/nutritionist/medical-history");
	}, []);

	return null;
}
